<template>
  <div class="overview-area ptb-100 bg-f9f9f9">
    <div class="container">
      <div class="section-title">
        <span class="sub-title">Discover Our Services</span>
        <h2>Trustworthy Solutions for Your Needs</h2>
      </div>
      <div v-for="(service,i) in services" :key="service.title" class="overview-box">
        <div class="row align-items-center">
          <div :class="{'order-last':i%2===0}" class="col-lg-6 col-md-12 overview-image">
            <img src="../../assets/images/overview/overview1.png" data-aos="fade-up" alt="overview">
          </div>
          <div class="col-lg-6 col-md-12 overview-content">
            <h2>{{ service.title }}</h2>
            <ul class="overview-list">
              <li v-for="option in service.options" :key="option" data-aos="fade-up" data-aos-duration="1200">
                <span>
                  <i class="flaticon-draw-check-mark"></i>
                  {{ option }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PageTitle from "@/components/Common/PageTitle.vue";

const services = [
  {
    title: "Web Development",
    options: [
      "Custom Website Design",
      "Front-End Development",
      "Back-End Development",
      "E-Commerce Solutions",
      "Content Management Systems",
      "Responsive and Mobile Optimization"
    ]
  },
  {
    title: "Native App Development",
    options: [
      "iOS App Development",
      "Android App Development",
      "Cross-Platform App Development",
      "App UI/UX Design",
      "App Testing and Quality Assurance",
      "App Maintenance and Updates"
    ]
  },
  {
    title: "Digital Strategy",
    options: [
      "Project Consulting",
      "Strategic Planning",
      "Market Research",
      "User Experience (UX) Analysis",
      "Competitor Analysis",
      "Digital Transformation Services"
    ]
  },
  {
    title: "E-Commerce Solutions",
    options: [
      "Online Store Development",
      "Payment Gateway Integration",
      "Shopping Cart Optimization",
      "Product Catalog Management",
      "Inventory and Order Management",
      "E-Commerce SEO"
    ]
  },
  {
    title: "Cloud Services",
    options: [
      "Cloud Hosting and Migration",
      "Infrastructure as a Service (IaaS)",
      "Platform as a Service (PaaS)",
      "Data Storage and Management",
      "Scalability and Performance Optimization",
      "Cloud Security"
    ]
  },
  {
    title: "UI/UX Design",
    options: [
      "User Interface (UI) Design",
      "User Experience (UX) Design",
      "Wireframing and Prototyping",
      "Interactive Design",
      "Usability Testing",
      "Branding and Identity Design"
    ]
  },
  {
    title: "Digital Marketing",
    options: [
      "Search Engine Optimization (SEO)",
      "Pay-Per-Click (PPC) Advertising",
      "Social Media Marketing",
      "Email Marketing",
      "Content Marketing",
      "Analytics and Reporting"
    ]
  },
  {
    title: "Maintenance and Support",
    options: [
      "Regular Website/App Updates",
      "Bug Fixing and Issue Resolution",
      "Security Audits and Updates",
      "Performance Optimization",
      "Technical Support and Training",
      "24/7 Monitoring and Emergency Support"
    ]
  },
  {
    title: "Consulting Services",
    options: [
      "IT Strategy Consulting",
      "Technology Stack Assessment",
      "Digital Transformation Planning",
      "Project Management",
      "IT Infrastructure Optimization",
      "Cost-Benefit Analysis"
    ]
  }
]
</script>