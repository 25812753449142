<template>
    <div>
        <Navbar />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <h1>Terms & Conditions</h1>
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
        <TermsConditions />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import TermsConditions from '../TermsConditions/TermsConditions'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'TermsConditionsPage',
    components: {
        Navbar,
        TermsConditions,
        FooterStyleTwo,
    }
}
</script>