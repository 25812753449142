<template>
    <div class="team-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="team-content" data-aos="fade-up" data-aos-duration="1200">
                        <span class="sub-title">Team</span>
                        <h2>Want to be a part of our team?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus auctor purus risus, eu vitae neque, a platea sit. Dui nisi tempus in ac arcu. In neque laoreet mi malesuada quam morbi. Nisl sed a risus vitae, platea eget. Tortor, nisl aliquam urna dignissim.</p>
                        <router-link to="/contact" class="btn-style-one red-light-color">
                            Contact Us 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="team-member-list">
                        <ul>
                            <li data-aos="zoom-in" data-aos-duration="1200">
                                <img src="../../assets/images/team/team1.png" alt="member-image">
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1200" data-aos-delay="100">
                                <img src="../../assets/images/team/team2.png" alt="member-image">
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1200" data-aos-delay="200">
                                <img src="../../assets/images/team/team3.png" alt="member-image">
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <img src="../../assets/images/team/team4.png" alt="member-image">
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
                                <img src="../../assets/images/team/team5.png" alt="member-image">
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1200" data-aos-delay="500">
                                <img src="../../assets/images/team/team6.png" alt="member-image">
                            </li>
                        </ul>
                        <img src="../../assets/images/shape/bg-shape1.jpg" alt="bg-image" class="bg-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="team-area pb-75">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-team-member bg1">
                        <img src="../../assets/images/team/team7.jpg" alt="member-image">
                        <div class="content">
                            <h3>Franco Gino</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                        <i class="flaticon-facebook-app-symbol"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="flaticon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="flaticon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-team-member bg2">
                        <img src="../../assets/images/team/team8.jpg" alt="member-image">
                        <div class="content">
                            <h3>Emila Lucy</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                        <i class="flaticon-facebook-app-symbol"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="flaticon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="flaticon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-team-member bg3">
                        <img src="../../assets/images/team/team9.jpg" alt="member-image">
                        <div class="content">
                            <h3>Alina Smith</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                        <i class="flaticon-facebook-app-symbol"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="flaticon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="flaticon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-team-member bg4">
                        <img src="../../assets/images/team/team10.jpg" alt="member-image">
                        <div class="content">
                            <h3>Andrea Romeo</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                        <i class="flaticon-facebook-app-symbol"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="flaticon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="flaticon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-team-member bg5">
                        <img src="../../assets/images/team/team11.jpg" alt="member-image">
                        <div class="content">
                            <h3>Amy Jones</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                        <i class="flaticon-facebook-app-symbol"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="flaticon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="flaticon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-team-member bg6">
                        <img src="../../assets/images/team/team12.jpg" alt="member-image">
                        <div class="content">
                            <h3>David Warner</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                        <i class="flaticon-facebook-app-symbol"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="flaticon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="flaticon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-team-member bg7">
                        <img src="../../assets/images/team/team13.jpg" alt="member-image">
                        <div class="content">
                            <h3>James Andy</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                        <i class="flaticon-facebook-app-symbol"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="flaticon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="flaticon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-team-member bg8">
                        <img src="../../assets/images/team/team14.jpg" alt="member-image">
                        <div class="content">
                            <h3>Sarah Taylor</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                        <i class="flaticon-facebook-app-symbol"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="flaticon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="flaticon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Team'
}
</script>