<template>
    <div>
        <Navbar />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <span class="sub-title">Frequently Ask & Question</span>
                    <h1>Ciao! How Can We Help You?</h1>
                    <form @submit.prevent>
                        <label><i class="ph-magnifying-glass"></i></label>
                        <input type="text" class="input-search" placeholder="Search a question...">
                        <button type="submit">Search</button>
                    </form>
                </div>
            </div>
        </div>
        <Faq />
        <Partner />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Faq from '../Faq/Faq'
import Partner from '../Faq/Partner'
import Footer from '../Layouts/Footer'

export default {
    name: 'FaqPage',
    components: {
        Navbar,
        Faq,
        Partner,
        Footer,
    }
}
</script>