<template>
    <div class="template-footer-four pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget">
                        <h3 class="nunito-font">About Us</h3>
                        <ul class="quick-links">
                            <li><router-link to="/about-us">Our Story</router-link></li>
                            <li><router-link to="/contact">Careers</router-link></li>
                            <li><router-link to="/blog-grid">Latest News</router-link></li>
                            <li><router-link to="/contact">Contact Us</router-link></li>
                            <li><router-link to="/terms-conditions">Terms & Condition</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget">
                        <h3 class="nunito-font">Resources</h3>
                        <ul class="quick-links">
                            <li><router-link to="/portfolio-details">Business Startup</router-link></li>
                            <li><router-link to="/portfolio-details">Finance Consulting</router-link></li>
                            <li><router-link to="/portfolio-details">Wealth Management</router-link></li>
                            <li><router-link to="/portfolio-details">Business Planning</router-link></li>
                            <li><router-link to="/portfolio-details">Data Management</router-link></li>
                            <li><router-link to="/portfolio-details">Marketing Planning</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget">
                        <h3 class="nunito-font">Quick Links</h3>
                        <ul class="quick-links">
                            <li><router-link to="/insurance">Home</router-link></li>
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <li><router-link to="/blog-grid">Latest News</router-link></li>
                            <li><router-link to="/contact">Contact Us</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/terms-conditions">Terms & Condition</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget">
                        <h3 class="nunito-font">Newsletter</h3>
                        <div class="box">
                            <p>Latest resources, sent to your inbox weekly</p>
                            <form class="newsletter-form" @submit.prevent>
                                <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                                <button type="submit" class="btn-style-one dark-green-color">Subscribe Now <i class="ph-caret-right"></i></button>
                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-5">
                        <p>
                            @{{currentYear}} Brilliant Softs. All Rights Reserved by
                            <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>
                        </p>
                    </div>
                    <div class="col-lg-4 col-md-3">
                        <div class="logo">
                            <router-link to="/">
                                <img src="../../assets/images/logo.png" alt="logo">
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <ul class="social-links">
                            <li>
                                <a href="https://www.https://www.linkedin.com/.com/" target="_blank">
                                    <i class="flaticon-facebook-app-symbol"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank">
                                    <i class="flaticon-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/" target="_blank">
                                    <i class="flaticon-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" target="_blank">
                                    <i class="flaticon-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterStyleFour',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>