<template>
    <div class="help-desk-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">How we can help you</span>
                <h2>Count on Us to Facilitate Your Project's Success</h2>
            </div>
            <div class="row">
                <div v-for="service in services" :key="service.title" class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <img :src="require(`../../assets/images/icon/icon1.png`)" :alt="service.title">
                        </div>
                        <h3>{{ service.title }}</h3>
                        <p>{{service.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const services = [
  {
    title: 'Strategic Guidance',
    description: 'We work closely with you to define clear project goals, outline a roadmap, and set the stage for success.',
    icon: 'icon1.png',
    link: '/services-details'
  },
  {
    title: 'Technical Expertise',
    description: 'Our skilled team excels in web and app development, bringing your vision to life with precision.',
    icon: 'icon2.png',
    link: '/services-details'
  },
  {
    title: 'Effective Collaboration',
    description: 'Your input guides our work; we foster a collaborative atmosphere to ensure your project\'s success.',
    icon: 'icon3.png',
    link: '/services-details'
  },
  {
    title: 'Timely Delivery',
    description: 'We\'re committed to meeting deadlines, ensuring your project launches on schedule, be it a website or native app.'
  }
]
</script>