<template>
    <div>
        <Navbar />
        <MainBanner />
        <HelpDesk />
        <Services id="services"/>
        <WhatWeDo />
        <Faq />
        <Testimonials />
<!--        <Blog />-->
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../ITStartup/MainBanner'
import HelpDesk from '../ITStartup/HelpDesk'
import Services from '../ITStartup/Services'
import WhatWeDo from '../ITStartup/WhatWeDo'
import Faq from '../ITStartup/Faq'
import Testimonials from '../Common/Testimonials'
import Blog from '../Common/Blog'
import Footer from '../Layouts/Footer'

export default {
    name: 'ITStartupPage',
    components: {
        Navbar,
        MainBanner,
        HelpDesk,
        Services,
        WhatWeDo,
        Faq,
        Testimonials,
        Blog,
        Footer,
    }
}
</script>