<template>
    <div>
        <Navbar />
        <PageTitle subTitle="Get in Touch" heding="Connecting People With Knowledge" />
        <Contact />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Contact from '../Contact/Contact'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'ContactPage',
    components: {
        Navbar,
        PageTitle,
        Contact,
        FooterStyleTwo,
    }
}
</script>