<template>
    <div class="what-we-do-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Our Specialty</span>
                <h2>Elevating Excellence in Every Niche</h2>
            </div>
            <div class="row">
                <div v-for="specialty in specialties" :key="specialty.title" class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-what-we-do-box">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon5.png" alt="icon">
                        </div>
                        <h3>{{ specialty.title }}</h3>
                        <p>{{specialty.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const specialties=[
  {
    title:'Web Innovation',
    description: 'We specialize in pioneering web solutions that push boundaries, from cutting-edge designs to immersive user experiences. Your web project will benefit from our innovative spirit.',
    icon: '',
  },
  {
    title:'App Expertise',
    description: 'Our native app development mastery covers iOS, Android, and cross-platform solutions. Your app will shine with intuitive design and seamless functionality.',
    icon: '',
  },
  {
    title:'Digital Strategy',
    description: 'Tailored strategies, market insights, and transformative planning are our forte. Leverage our expertise to unlock new avenues of digital success.',
    icon: '',
  },
  {
    title:'Security & Reliability',
    description: 'We prioritize your project\'s safety with top-tier cybersecurity measures. Count on us for secure coding, threat prevention, and robust incident response.',
    icon: '',
  }
]
</script>