<template>
  <div class="faq-area with-top-border ptb-100">
    <div class="container">
      <div class="row m-0 align-items-end">
        <div class="col-12 p-0">
          <div class="faq-accordion">
            <div class="section-title">
              <span class="sub-title">Frequently Ask & Question</span>
              <h2>Dedicated to help anything people’s needs</h2>
            </div>
            <div class="accordion" id="faqAccordion">
              <div v-for="(faq,i) in faqs" class="accordion-item" data-aos="fade-up" data-aos-duration="500">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#${i}`" aria-expanded="false" :aria-controls="i">
                  {{ faq.question }}
                </button>
                <div :id="i" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body">
                    <p>{{ faq.answer }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const faqs = [
  {
    question: 'How can your web development services benefit my business?',
    answer: 'Our web development services can enhance your online presence, improve user experience, and boost your brand\'s visibility, ultimately driving business growth.'
  },
  {
    question: 'Can you handle both large and small-scale projects for web development?',
    answer: 'Yes, we cater to projects of all sizes. Our web development services are scalable, ensuring quality and performance, whether it\'s a small business website or a large-scale enterprise solution.'
  },
  {
    question: 'Can you customize your web design to match our brand\'s unique identity?',
    answer: 'Absolutely, our custom website design services are tailored to reflect your brand\'s identity, ensuring a unique and cohesive online presence.'
  },
  {
    question: 'What distinguishes your native app development from others in the market?',
    answer: 'Our native app development stands out due to our focus on intuitive design, seamless functionality, and exceptional user experiences, making your app highly competitive.'
  },
  {
    question: 'What kind of support and maintenance services do you offer for websites and apps?',
    answer: 'We provide regular updates, bug fixing, security audits, performance optimization, technical support, and 24/7 monitoring for uninterrupted website and app operation.'
  },
  {
    question: 'How can digital strategy consulting help me achieve my business goals?',
    answer: 'Digital strategy consulting provides a clear roadmap for achieving your business objectives, optimizing your online presence, and increasing revenue.'
  },
  {
    question: 'What\'s involved in your IT Strategy Consulting services?',
    answer:'Our IT Strategy Consulting involves a comprehensive assessment of your current technology landscape, strategic planning, and recommendations to align technology with your business goals.'
  },
  {
    question: 'What e-commerce solutions do you offer, and how can they boost my online store?',
    answer: 'Our e-commerce solutions include online store development, payment gateway integration, and SEO optimization, designed to improve your store\'s performance and customer experience.'
  },
  {
    question: 'What are the advantages of cloud services for my business, and how can you help with them?',
    answer: 'Cloud services offer scalability, cost-efficiency, and flexibility. We assist in migrating, managing, and optimizing your cloud infrastructure for maximum benefits.'
  },
  {
    question: 'What sets your UI/UX design services apart, and how can they improve my digital products?',
    answer: 'Our UI/UX design expertise ensures user-friendly interfaces, resulting in higher user satisfaction, engagement, and improved digital product performance.'
  },
  {
    question: 'Can your digital marketing services increase my website\'s visibility and traffic?',
    answer: 'Our digital marketing strategies boost your website\'s search engine rankings, increase online visibility, and drive targeted traffic to your site.'
  },
  {
    question: ' How can your IT consulting services help me optimize my technology stack and infrastructure?',
    answer: 'Our IT consulting services streamline your technology stack, reduce costs, and enhance infrastructure efficiency, aligning your IT with your business goals.'
  },
  {
    question: ' How do your Digital Transformation Services work, and why are they important?',
    answer: 'Our Digital Transformation Services help businesses adapt to the digital age. We analyze your existing processes, recommend technology improvements, and guide your transformation to increase efficiency and competitiveness.'
  },
  {
    question: 'What benefits can I expect from your SEO services for my website?',
    answer: 'Our SEO services aim to improve your website\'s search engine rankings, drive organic traffic, enhance user experience, and ultimately boost online visibility and conversion rates.'
  },
  {
    question: 'Do you provide ongoing training for my team after implementing your solutions?',
    answer: 'Yes, we offer post-implementation training and support to ensure your team can efficiently operate and maintain the solutions we provide, ensuring long-term success.'
  },
  {
    question: 'What is the typical timeline for a native app development project?',
    answer: 'The timeline varies depending on the complexity of the app, but we work efficiently to deliver quality apps within a reasonable timeframe.'
  },
  {
    question: 'How can your content management systems (CMS) improve my website\'s content management process?',
    answer: ' Our CMS solutions simplify content creation, editing, and publishing, empowering you to manage your website\'s content easily and efficiently.'
  },
  {
    question: 'What steps do you take to ensure the security of my e-commerce website and customer data?',
    answer: 'We implement industry-standard security measures, including SSL encryption, PCI compliance, and regular security audits, to safeguard your e-commerce website and customer information.'
  },
  {
    question: 'Can you provide references or case studies of successful projects you\'ve completed?',
    answer: 'Yes, we have a portfolio of successful projects and can provide references and case studies upon request, demonstrating our expertise and track record.'
  },
]
</script>