<template>
  <div :class="['navbar-area', { 'is-sticky': isSticky }]">
    <div class="vumy-nav">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <router-link class="navbar-brand" to="/">
            <img src="../../assets/images/logo.png" alt="logo">
          </router-link>

          <div
              class="navbar-toggler"
              @click="show = !show"
              :aria-pressed="show ? 'true' : 'false'"
              v-bind:class="{ show: button_show_state }"
              v-on:click="button_show_state = !button_show_state"
          >
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </div>

          <div class="collapse navbar-collapse" :class="{ show: show }">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/services" class="nav-link">Services</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/portfolio" class="nav-link">Portfolio</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/contact" class="nav-link">Contact</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isSticky: false,
      show: false,
      button_show_state: false,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
}
</script>