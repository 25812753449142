<template>
  <div>
    <Navbar/>
    <Services/>
    <Footer/>
  </div>
</template>

<script>
import Services from "@/components/ITStartup/Services.vue";
import Navbar from "@/components/Layouts/Navbar.vue";
import Footer from "@/components/Layouts/Footer.vue";

export default {
  name: 'ServicesPage',
  components: {
    Services,
    Navbar,
    Footer,
  }
}
</script>